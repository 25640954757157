<template>
  <!-- 页面底部 -->
  <div>
    <div class="footer">
      <div class="banxin">
        <div class="content">
        <!-- <div :class="['content', $store.state.orgId == 1 ? 'max-250' : '']"> -->
          <!-- 爱舞功 -->
         <!--  <div class="left" v-if="$store.state.orgId == 1">
            <div class="left_item" v-for="firstItem in data" :key="firstItem.directoryNo">
              <div class="title one-t">
                <span v-if="!firstItem.link" :title="firstItem.name">{{ firstItem.name }}</span>
                <a v-else :href="firstItem.link" target="_blank" :title="firstItem.name">{{ firstItem.name }}</a>
              </div>
              <div class="list awg-list" v-if="firstItem.children.length">
                <div class="item one-t" v-for="secondItem in firstItem.children.slice(0, 12)"
                  :key="secondItem.directoryNo">
                  <span v-if="!secondItem.link" :title="secondItem.name">{{ secondItem.name }}</span>
                  <a v-else :href="secondItem.link" target="_blank" :title="secondItem.name">{{ secondItem.name }}</a>
                </div>
              </div>
            </div>
          </div> -->
          <!-- 坚果 -->
          <div class="left jg-left">
            <div class="left_item jg-left_item" v-for="firstItem in data" :key="firstItem.directoryNo">
              <div class="title one-t">
                <span v-if="!firstItem.link" :title="firstItem.name">{{ firstItem.name }}</span>
                <a v-else :href="firstItem.link" target="_blank" :title="firstItem.name">{{ firstItem.name }}</a>
              </div>
              <div class="list jg-list" v-if="firstItem.children.length">
                <div class="item one-t" v-for="secondItem in firstItem.children.slice(0, 12)"
                  :key="secondItem.directoryNo">
                  <span v-if="!secondItem.link" :title="secondItem.name">{{ secondItem.name }}</span>
                  <a v-else :href="secondItem.link" target="_blank" :title="secondItem.name">{{ secondItem.name }}</a>
                </div>
              </div>
            </div>
          </div>
          <!--  <div class="right">
            <div class="code_box">
              <div class="wx1">
                <div class="qr_code"></div>
                <div class="text">微信公众号</div>
              </div>
              <div class="wx2">
                <div class="qr_code"></div>
                <div class="text">企业微信</div>
              </div>
            </div>
            <div class="other">
              <div class="title">社交媒体账号</div>
              <div class="list">
                <div class="item" v-for="(item, index) in mediaArr" :key="index">
                  <img :src="item" alt="">
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="line"></div>
      </div>
      <div class="bottom">
        <div class="logo">
          <img v-if="$store.state.footerLogo" :src="$store.state.footerLogo" alt="">
        </div>
        <div class="right">
          <div class="copy_right" @click="beian">
            <span v-if="$store.state.icpRecordNumber">{{ $store.state.icpRecordNumber }}</span>
            <span v-if="$store.state.copyright" style="margin-left: 5px;">{{ $store.state.copyright || '' }}</span>
          </div>
          <div class="copy_right" @click="gongan" v-if="$store.state.networkRecordNumber">
            {{ $store.state.networkRecordNumber }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/lang'
export default {
  props: ['data'],
  data() {
    return {
      navArr: [],
      mediaArr: [
        require('../../assets/images/TikTok.png'),
        require('../../assets/images/camera.png'),
        require('../../assets/images/Zhihu.png'),
        require('../../assets/images/Xiaohongshu.png'),
      ]
    };
  },
  methods: {
    beian() {
      if(this.$store.state.icpRecordNumber){
        window.open("https://beian.miit.gov.cn/#/Integrated/index");
      }
    },
    gongan() {
      window.open(
        `http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${this.$store.state.networkRecordNumber2 || ''}`
      );
    },
  },
};
</script>

<style lang="less" scoped>
.mr30 {
  margin-right: .3rem;
}

.big_img {
  width: 100%;

  img {
    display: block;
    width: 100%;
  }
}

.footer {
  width: 100%;
  min-width: 12rem;
  padding: 0.32rem 0 0;
  background: var(--footerColor);

  .content {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      position: relative;

      .left_item {
        flex: 1;
        display: inline-block;

        &:nth-of-type(1) {
          flex: 1.5;
          margin-right: .6rem;
        }

        &:nth-of-type(2) {
          margin-right: .6rem;
        }

        &:nth-of-type(4) {
          position: absolute;
          // left: 5.7rem;
          left: 7.22rem;
          bottom: -.2rem;
        }

        a {
          font-weight: 500;
          font-size: .16rem;
          font-size: .14rem !important;
          color: rgba(255, 255, 255, 0.8);
        }

        .title {
          height: .24rem;
          color: #fff;
          font-weight: 500;
          font-size: .16rem;
          line-height: .24rem;
          margin-bottom: .16rem;
          max-width: 2.5rem;

          a {
            font-size: .16rem !important;
            color: #fff;
          }
        }



        .list {
          // height: 2.1rem;
          display: flex;
          flex-direction: column;

          // overflow: hidden;
          .item {
            // cursor: pointer;
            // max-width: 1.1rem;
            font-weight: 400;
            line-height: .22rem;
            margin-bottom: .12rem;
            color: rgba(255, 255, 255, 0.8);

            :hover {
              color: #F1B434;
            }
          }
        }
      }
    }

    .right {
      margin-left: .2rem;
      margin-right: .11rem;

      .code_box {
        display: flex;

        .wx1,
        .wx2 {
          display: flex;
          flex-direction: column;
          align-items: center;


          &:last-child {
            margin-left: .48rem;
          }

          .qr_code {
            width: .96rem;
            height: 1.02rem;
            background: url('../../assets/images/wxCode.png') no-repeat;
            background-size: 100% 100%;
          }

          .text {
            line-height: .24rem;
          }
        }
      }

      .other {
        margin-top: .28rem;

        .title {
          color: #fff;
          font-weight: 500;
          font-size: .16rem;
          line-height: .24rem;
          margin-bottom: .14rem;
        }

        .list {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .item {
            display: flex;
            width: .48rem;
            height: .48rem;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            background-color: #fff;

            img {
              width: .28rem;
              height: .28rem;
            }

            &:nth-of-type(3) {
              img {
                width: .36rem;
                height: .16rem;
              }
            }

            &:nth-of-type(4) {
              img {
                width: .36rem;
                height: .13rem;
              }
            }
          }
        }
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    margin-top: 0.42rem;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .copy_right {
    height: 0.2rem;
    cursor: pointer;
    font-size: .12rem;
    line-height: 0.2rem;
    margin-bottom: .04rem;
    color: rgba(255, 255, 255, 0.6);
    // img {
    //   width: 0.15rem;
    //   height: 0.15rem;
    // }
  }

  .bottom {
    height: .76rem;
    width: 100%;
    display: flex;
    justify-content: center;

    .logo {
      margin-top: .12rem;

      img {
        // width: 1.6rem;
        // height: .44rem;
        max-width: 1.6rem;
        max-height: .44rem;
      }
    }

    .right {
      height: 100%;
      display: flex;
      margin-left: .28rem;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.text {
  line-height: 0.2rem;
  color: #ccc;
}

.max-250 {
  max-height: 2.5rem;
}

.color_grey {
  color: #d8d8d8;
}

.awg-list {
  max-height: 2.04rem;
  flex-wrap: wrap;
}

.jg-left{
  // max-width: 1200px;
  // flex-wrap: wrap;
}
.jg-left_item {
  flex: 1 !important;
  min-width: 1.5rem;
  margin-right: .1rem !important;

  &:nth-of-type(1) {
    flex: 1 !important;
  }

  &:nth-of-type(4) {
    left: 0 !important;
    bottom: 0 !important;
    position: relative !important;
  }
}
</style>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>
<style lang="less">
@import "./assets/css/global.less";

html {
  font-size: 100px !important;
}
html,
body {
  width: 100%;
  min-height: 100vh;
}
#app {
  font-family: PingFangSC-Semibold, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #000;
  font-size: 0.14rem;
  width: 100%;
  min-height: 100vh;
  // background-color: pink;
}
.el-message .el-icon-error,.el-message .el-icon-info,.el-message .el-icon-success,.el-message .el-icon-warning{
  font-size: 14px!important;
}
</style>
